.homeNavbar{
    height: 50px;
    border-bottom: 0.5px solid lightgray;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #555;
    background-color: lightblue;

    .wrapper{
        width: 100%;
        display: flex;
        align-items: center;
        padding: 20px;
        justify-content: space-between;

        .search{
            display: flex;
            align-items: center;
            border: 0.5px solid lightgray;
            padding: 3px;

            input{
                border: none;
                outline: none;
                background: transparent;
            }
        }

        .items{
            display: flex;
            align-items: center;

            .item{
                display: flex;
                align-items: center;
                margin-right: 20px;
                padding-right: 3px;
                position: relative;

                .icon{
                    font-size: 20px;
                }

                .counter{
                   width: 15px;
                   height: 15px; 
                   background-color: red;
                   border-radius: 50%;
                   color: white;
                   display: flex;
                   align-items: center;
                   justify-content: center;
                   font-size: 10px;
                   font-weight: bold;
                   position: absolute;
                   top: -5px;
                   right: -5px;
                }
            }
        }
    }
}