.datatable {
    height: 600px;
    padding: 20px;

    .datatableTitle {

        width: 100%;
        font-size: 24px;
        color: gray;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .link {
            text-decoration: none;
            background-color: aliceblue;
            color: green;
            font-size: 20px;
            font-weight: 400;
            border: 1px solid green;
            padding: 5px;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    .cellAction {
        display: flex;
        align-items: center;
        gap: 15px;



        .deleteButton {
            padding: 2px 5px;
            border-radius: 5px;
            color: crimson;
            border: 1px dotted rgba(220, 20, 60, 0.6);
            cursor: pointer;
        }

    }
}