.profile {
    display: flex;
    width: 100%;

    .profileContainer {
        flex: 6;


        .top,
        .body {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 10px;
            margin: 20px;

            h1 {
                color: rgba(128, 128, 128, 0.697);
                font-size: 20px;
                text-align: center;
                margin-top: 20px;
            }

            form {


                display: flex;
                flex-direction: column;
                gap: 30px;
                margin-left: auto;
                margin-right: auto;
                width: 40%;


                .formInput {
                    width: 75%;

                    label {
                        display: flex;
                        align-items: center;
                        gap: 10px;


                    }

                    input {
                        width: 100%;
                        padding: 5px;
                        border: none;
                        border-bottom: 1px solid gray;
                    }

                }

                .admin{
                    cursor: pointer;
                    font-weight: bold;
                    
                }

                .submit {

                    width: 250px;
                    padding: 10px;
                    border: none;
                    border-radius: 9px;
                    background-color: teal;
                    color: white;
                    font-weight: bold;
                    cursor: pointer;
                    margin-top: 25px;
                    margin-bottom: 20px;
                }


            }

        }
    }
}