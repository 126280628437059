@mixin phone{
    @media only screen and (max-width: 768px){
        @content;
    }
}


.loginContainer {
    min-height: 100vh;
    background: url("../../../public/img/water.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    margin: 0;
    position: relative;
    width: 100%;
    overflow-x: hidden;


    .isos {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;


        .iso3 {
            display: flex;
            flex-direction: row;
            padding: 20px;
            padding-bottom: 0;



            .isoImg3 {

                margin-right: 15px;
                padding: 10px;
                width: 120px;
                height: 100px;
                border-radius: 9px;
            }



        }

        .iso4 {
            display: flex;
            padding: 20px;
            padding-bottom: 0;



            .isoImg4 {

                padding: 10px;
                width: 300px;
                height: 120px;
                border-radius: 9px;
            }

        }

    }

    @include phone {
        .isos{
            display: flex;
            justify-content: center;
            

            .iso3{
                padding: 10px;
                

                .isoImg3{
                    
                    width: 80px;
                    height: 60px;
                }
            }

            .iso4{
                padding: 10px;

                .isoImg4{
                    
                    width: 200px;
                    height: 60px;
                }
            }
        }
    }

    .img {

        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        padding-bottom: 50px;

        .img .logo {
            padding: 50px;
            padding-top: 0;
            border-radius: 9px;

        }

        
    }

    .smallImg{
        display: none;
    }

    @include phone {
        .img{
           display: none; 
        }

        .smallImg{
            display: flex;
            justify-content: center;
            margin-top: 80px;
            margin-bottom: 80px;

            .miniLogo{
                width: 280px;
                height: 180px;
            }
        }
    }

    .login {

        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 0;



        form {
            display: flex;
            flex-direction: column;
            align-items: center;


            input {
                width: 200px;
                height: 30px;
                margin: 10px;
                border: 1px solid black;
                border-radius: 5px;

            }

            button {
                margin-top: 20px;
                width: 200px;
                height: 30px;
                border: none;
                background-color: purple;
                color: white;
                font-weight: bold;
                cursor: pointer;
            }

            

            span {
                font-size: 1rem;
                color: red;
                margin-top: 10px;
            }

            
        }

        .iso {
            display: flex;
            justify-content: space-between;



            .iso1 {
                display: flex;
                flex-direction: row;
                padding: 20px;



                .isoImg1 {

                    margin-right: 15px;
                    margin-bottom: 15px;
                    padding: 10px;
                    width: 120px;
                    height: 100px;
                    border-radius: 9px;
                }



            }

            .iso2 {
                display: flex;
                flex-direction: column;
                padding: 20px;



                .isoImg2 {

                    padding: 10px;
                    width: 300px;
                    height: 120px;
                    border-radius: 9px;
                }

            }
        }

        .cmleonResp{
            display: none
        }

        @include phone {
            .cmleonResp {

                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 80px;
                
    
                .rightsResp{
                    font-size: small;
                }
    
                .logoCmleonResp {
                    width: 35px;
                    height: 35px;
                    margin-left: 15px;
                    margin-right: 15px;
                    margin-bottom: 15px;
                }
            }
        }

        

    }

    .cmleon {
        display: flex;
        justify-content: end;
        align-content: flex-end;
        margin-right: 10px;
        margin-left: auto;
        position: absolute;
        bottom: 0px;
        right: 0px;

        


        .rights p {
            font-weight: lighter;
            
        }

        .logoCmleon {
            width: 50px;
            height: 50px;
        }
    }
    @include phone {
        .cmleon{
            display: none;
        }
    }


}