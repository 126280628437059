.homeSidebar{
    flex: 1;
    border-right: 0.5px solid lightgray;
    min-height: 100vh;
    

    .top{
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: lightblue;

        .logo{
            font-size: 20px;
            font-weight: bold;
            color: #6439ff;
        }
    }

    hr{
        height: 0;
        border: 0.5px solid lightgray;
    }

    .center{
        padding-left: 10px;

        .link{
            text-decoration: none;
            color: #888;
        }

        .link-active{
            color: #7451f8;
            text-decoration: none;
        }

        ul{
            list-style: none;
            margin: 0;
            padding: 0;

            .title{
                font-size: 1rem;
                font-weight: bold;
                color: #999;
                margin-top: 15px;
                margin-bottom: 5px;
            }

            li{
                display: flex;
                align-items: center;
                padding: 5px;
                cursor: pointer;

                 

                .icon{
                    font-size: 25px;
                    color: #7451f8;
                    margin-right: 10px;
                }

                span {
                    font-size: 15px;
                    font-weight: 600;
                    
                    margin-left: 10px;
                }
            }
        }
    }

    
}