.signatureCanvas{
  
    margin: auto;
    margin-bottom: 20px;
    border: 1px solid black;
    width: 50%;
    max-height: 300px;
    background-color: antiquewhite;
}

.input {
  box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff;
  background: transparent;
  padding: 0.4375rem 0.75rem;
  border: 0;
  outline: 1px solid #eeeef0;
  font-size: 0.875rem;
  height: 30px;
  line-height: 1.5;
  border-radius: 0.3rem;
  width: 250px;
  padding-left: 70px;
}

.icon{
  cursor: pointer;
}

