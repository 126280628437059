.checkMaterielSingle {

    width: 100%;

    .checkMaterielSingleContainer {
        flex: 6;
    }

    .print {
        margin-left: 50px;
        cursor: pointer
    }

    .button {

        width: 250px;
        padding: 10px;
        border: none;
        border-radius: 9px;
        background-color: teal;
        color: white;
        font-weight: bold;
        cursor: pointer;
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .signature {
        margin: auto;
        margin-bottom: 20px;
        padding: 50px;
        border: 1px solid black;
        width: 50%;
        max-height: 300px;
        background-color: antiquewhite;
    }
}

@media print {

    .newPage {
        page-break-before: always;
    }

}