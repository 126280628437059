.signatureCanvas{
  
  margin: auto;
  margin-bottom: 20px;
  border: 1px solid black;
  width: 50%;
  max-height: 300px;
  background-color: antiquewhite;
}


