.listClients{
    display: flex;
    width: 100%;

    .listClientsContainer{
        flex: 6;

        h1{
            text-align: center;
            margin-top: 5px;
            color: lightgray;
            font-size: 24px;

        }
    }

}