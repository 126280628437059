.datatableRapports{
    height: 600px;
    padding: 20px;
  
    

    .cellWithStatus {
        padding: 5px;
        border-radius: 5px;
        background-color: rgba(255, 217, 0, 0.05);

       
    }
  
    .cellAction {
      display: flex;
      align-items: center;
      gap: 15px;
  
      .editButton {
        padding: 2px 5px;
        border-radius: 5px;
        color: darkblue;
        border: 1px dotted rgba(0, 0, 139, 0.596);
        cursor: pointer;
      }
  
      .deleteButton {
        padding: 2px 5px;
        border-radius: 5px;
        color: crimson;
        border: 1px dotted rgba(220, 20, 60, 0.6);
        cursor: pointer;
      }
  
    }
  
  }