.widget{
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 10px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
    box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
    border-radius: 10px;
    height: 100px;

    .left, .right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .title{
            font-weight: bold;
            font-size: 14px;
            color: gray;
            
        }

        .counter{
            width: 15px;
            height: 15px; 
            background-color: red;
            border-radius: 50%;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            font-weight: bold;
            position: absolute;
            top: -5px;
            right: -5px;
         }

        .link{
            width: max-content;
            font-size: 12px;
            border-bottom: 1px solid gray;
        }

        
        .icon{
            font-size: 2rem;
            padding: 5px;
            border-radius: 5px;
            align-self: flex-end;
        }
    }
}