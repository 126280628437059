@mixin phone{
    @media only screen and (max-width: 768px){
        @content;
    }
}


.emailContainer {
    background: url("../../../public/img/rapports.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    overflow-x: hidden;

    .accessLogo {

        width: 100%;
        display: flex;
        justify-content: space-between;


        .logoAccess {
            width: 300px;
            height: 160px;
            border-radius: 9px;
            margin-left: 35px;

        }

        .deco {
            background-color: orange;
            color: black;
            border-radius: 6px;
            height: 45px;
            margin-right: 25px;
            margin-top: 25px;
        }


    }

    @include phone {
        .accessLogo{

            .logoAccess{
                width:240px;
                height: 100px;
            }
        }
    }


    .email {

        height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;



        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            background-color: whitesmoke;
            border-radius: 5px;

            .title {
                color: black;
                font-size: larger;
                font-weight: bold;
            }


            input,
            select {
                width: 200px;
                height: 30px;
                margin: 10px;
                color: black;
                border: 1px solid black;
            }

            button {
                margin-top: 20px;
                margin-bottom: 20px;
                width: 200px;
                height: 30px;
                border: none;
                background-color: purple;
                color: white;
                font-weight: bold;
                cursor: pointer;
            }

            span {
                font-size: 1rem;
                color: red;
                margin-top: 10px;
            }
        }

        .iso {
            width: 100%;
            display: flex;
            justify-content: space-between;



            .iso1 {
                display: flex;
                flex-direction: row;
                padding: 20px;
                margin-left: 35px;



                .isoImg1 {

                    margin-right: 15px;
                    padding: 10px;
                    width: 120px;
                    height: 100px;
                    border-radius: 9px;
                }



            }

            .iso2 {
                
                padding: 20px;
                margin-right: 35px;


                .isoImg2 {

                    padding: 10px;
                    width: 300px;
                    height: 100px;
                    border-radius: 9px;
                }

            }
        }
        @include phone {
            .iso{
                display: flex;
                justify-content: space-evenly;
                
                .iso1{
                    padding: 10px;

                    .isoImg1{
                        margin-right: 5px;
                        width: 80px;
                        height: 60px;
                    }
                }
                .iso2{
                    padding: 10px;

                    .isoImg2{
                        margin-right: 5px;
                        width: 200px;
                        height: 60px;
                    }
                }

            }
        }
    }
}