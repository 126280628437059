
.notActive {
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none;
    font-size: large;
    color: white
}

.notActive:hover {
    color:white !important;
}

.active{
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none;
    font-size: large;
    color: orange
}

.active:hover {
    color:orange !important;
}