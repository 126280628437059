.rapportsPassage{
    display: flex;
    width: 100%;

    .rapportsPassageContainer{
        flex: 6;

        
           
    }
}